<template>
  <div class="container-web-app">
    <ArrowBack :path-name="toPageUrl($options.name)" />
    <br />
    <v-subheader class="headline text-center font-weight-bold d-block"> Time converter </v-subheader>
    <div class="subtitle-1">
      This converter will sum up any supported time units and output them in the highest unit(s) available.
    </div>

    <v-expansion-panels class="mt-4">
      <v-expansion-panel
        :class="{
          'v-expansion-panel--dark': isDarkModeEnabled,
          'v-expansion-panel--light': !isDarkModeEnabled
        }"
      >
        <v-expansion-panel-header class="font-weight-bold"> Supported time units </v-expansion-panel-header>
        <v-expansion-panel-content>
          <span v-for="(unit, i) of unitsSupported" :key="unit.long">
            {{ i === 0 ? pascalCase(unit.long) : unit.long }}s{{ unitsSupported.length - 1 > i ? ", " : "" }}
          </span>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        :class="{
          'v-expansion-panel--dark': isDarkModeEnabled,
          'v-expansion-panel--light': !isDarkModeEnabled
        }"
      >
        <v-expansion-panel-header class="font-weight-bold"> Input template </v-expansion-panel-header>
        <v-expansion-panel-content>
          <abbr v-for="(unit, i) of unitsReversed" :key="unit.long" :title="pascalCase(unit.long)"
            >{{ unit.short }}{{ unitsSupported.length - 1 > i ? ":" : "" }}</abbr
          >
          <br />
          You can use commas ( , ) for thousand separation
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-textarea v-model="query" autofocus class="mt-4" placeholder="Enter your input" @keypress="preventInvalidInput" />

    <transition name="slide">
      <div v-if="query !== ''">
        Result: <br />
        <span class="font-weight-bold">{{ output.outputTime }}</span> <br />
        Which is equivalent to <br />
        <span class="font-weight-bold">{{ output.outputText }}</span>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArrowBack from "@/components/ArrowBack";
import { convertTime } from "@/views/products/calculators";

export default {
  name: "TimeConverter",
  components: { ArrowBack },
  data() {
    return {
      query: "",
      output: {},
      unitsSupported: [
        {
          short: "SS",
          long: "second"
        },
        {
          short: "MM",
          long: "minute"
        },
        {
          short: "HH",
          long: "hour"
        },
        {
          short: "DD",
          long: "day"
        },
        {
          short: "WW",
          long: "week"
        },
        {
          short: "MM",
          long: "month"
        },
        {
          short: "YY",
          long: "year"
        }
      ]
    };
  },
  watch: {
    async query(input) {
      this.output = await convertTime(input);
    }
  },
  computed: {
    ...mapGetters(["toPageUrl"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.$options.name);
    },
    unitsReversed() {
      return [...this.unitsSupported].reverse();
    }
  },
  methods: {
    preventInvalidInput(e) {
      if (e.key.match(/[^\d:,.]/) && e.key !== "Enter") {
        e.preventDefault();
      }
    },
    pascalCase(text) {
      return text[0].toUpperCase() + text.substring(1);
    }
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.slide-leave,
.slide-enter-to {
  max-height: 400px;
}
</style>
